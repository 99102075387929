export default [
  {
    key: 'lotteryId',
    label: 'field.lotteryName',
    rules: 'required',
    type: '',
  },
  {
    key: 'lotteryTimeId',
    label: 'field.lotteryTime',
    rules: 'required',
    type: '',
  },
  {
    key: 'post',
    label: 'field.channel',
    rules: 'required',
    type: '',
  },
  {
    key: 'digit',
    label: 'field.digit',
    rules: 'required',
    type: 'radio',
    options: [
      {
        text: "field.2DigitNumber",
        value: 2,
      }, {
        text: "field.3DigitNumber",
        value: 3,
      }
    ],
  },
  {
    key: 'maxWinAmount',
    label: 'field.maxWinAmount',
    rules: 'required|decimal:2',
    type: 'currency',
  },
]
